import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import Select from 'react-select'
import { submitRegistration } from "./RegistrationUtility"
import {activities, preferredRoomType, travelInformation, getApparelSizeOptions,specialAccomodations, dietaryRestrictions, getCountrySelectOptions, getStateSelectOptions, getTimezoneOptions, getTimezoneInfo, getJobTypes, getActivities, getArrivalDateOptions, getDepartureDateOptions}  from "./RegistrationOptions"
import RegistrationInvitationModal from "./RegistrationInvitationModal"
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageOneError: null,
      pageThreeError: null,
      pageTwoError: null,
      checkError: false,
      emailTaken: false,
      dietaryRestrictions: [],
      additionalDates: [],
      saving: false,
      selectedActivities: [],
      features: props.features,
      participant: props.participant,
      popoutIsOpen: false
    }
    this.togglePopout = this.togglePopout.bind(this);
  }

  renderTester() {
    const { participant } = this.props;
    if(participant.test_flag && (participant.email||"").indexOf("@jaguardesignstudio") != -1) {
      const pages = [
        {id: 1},
        {id: 2}
      ];

      return (
        <div className="sg-reg-page-swapper">
          {pages.map(x=>(
            <div onClick={()=>this.setState({
              page: x.id
            })}className="sg-reg-page-swapper-item">
              Go To Page {x.id}
            </div>
          ))}
        </div>
      )
    }
  }

  togglePopout() {
    const { popoutIsOpen } = this.state;
    this.setState({popoutIsOpen: !popoutIsOpen});
  }

  pageHiddenClass(renderedPage) {
    const { page } = this.state;
    if(renderedPage == page) {
      return "visible";
    }
    return "hidden";
  }

  isEmailPersonal(email) {
    return false;
  }

  includesPolyfill(string1, string2) {
    return string1.indexOf(string2) != -1
  }

  isPageOneValid(formikProps) {
    //TODO
    const { fields } = this.props;
    const { values } = formikProps;
    let fieldList = [
      'name_first',
      'name_last',
      'email',
      'work_phone',
      'job_title',
      'company',
      // 'address_country',
      'ecfname',
      'eclname',
      'ecnumber',
      'ecrelation'
    ];

    if(values.user.bringingguest == 'True'){
      fieldList = fieldList.concat([
        'guestageconfirm',
        'guestfname',
        'guestlname'
      ])
    }
    if(values.user.includeassistant == 'True'){
      fieldList = fieldList.concat([
        'assistantname',
        'assistantemail'
      ])
    }

    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    if(values.user.confirmationofcompliance != "True" || values.user.termsandconditions != "True") {
      return false
    }
    return true;
  }

  renderGuestFields(formikProps) {
    const { values } = formikProps;
    const { checkError } = this.state;

    if(values.user.bringingguest == "True") {
      return (
        <>
          {/* <div className="col-xs-12">
            <label className="required label-english" style={{fontSize: "15px"}}> I confirm my guest is 18 years of age or older.  </label>
          </div> */}
          <div className="col-xs-12">
            {this.renderCheckbox('guestageconfirm',formikProps, false )}
            <label className="checkbox-label" for="topic_ids" style={{fontSize: "15px", verticalAlign:"middle", lineHeight:"19px", marginRight: "20px"}}>I confirm my guest is 21 years of age or older. *</label>
            <div className="registration-field-error">
              {checkError && !this.isPageOneValid(formikProps) && values.user.guestageconfirm != "True" ?
                <>Please click to confirm.</>
                :
                ""
              }
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Guest First Name </label>
            </div>
            {this.renderField('guestfname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Guest Last Name </label>
            </div>
            {this.renderField('guestlname',formikProps)}
          </div>
        </>
      )
    }
  }

  renderField(name,formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && (formikProps.values.user[name].length <= 0 || customValidation);
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    return (
      <Field disabled={properties['disabled']} onKeyUp={() => properties['onKeyUp'](formikProps)} component={properties['component']} className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`user[${name}]`}/>
    )
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = !renderOptions['not_required'] && checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['user'][name])[0]
    }catch (e) {

    }

    return (
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
        value={options ? value : ""}
        onChange={(option) => {
          const { user } = formikProps.values;
          user[name] = option.value
          formikProps.setFieldValue('user', user)
        }}
        filterOption={this.customFilter}
      />
    )
  }

  renderCheckbox(name, formikProps, customValidation=false, renderOptions={}) {
    let value = false;
    try {
      value = formikProps.values['user'][name] == "True";
    }catch(e){

    }
    if(renderOptions['forceValue'] != undefined ) {
      value = formikProps.values['user'][name] == renderOptions['forceValue']
    }
    return(
      <img
        src={ value  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        tabindex="0"
        onClick={(e) => {
          const { user } = formikProps.values;
          if(renderOptions['forceValue'] != undefined) {
            user[name] = renderOptions['forceValue']
          }else {
            user[name] = value ? "False" : "True"
          }
          formikProps.setFieldValue('user', user)
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            const { user } = formikProps.values;
            if(renderOptions['forceValue'] != undefined) {
              user[name] = renderOptions['forceValue']
            }else {
              user[name] = value ? "False" : "True"
            }
            formikProps.setFieldValue('user', user)
          }
        }}
      />
    )
  }

  renderMultipleSelect(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0));
    let value = null;
    try {
       value = formikProps.values['user'][name] ? formikProps.values['user'][name].map(x=> {return {value: x, label:x}}) : []
    }catch (e) {
      value = []
    }

    return (
      <>
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        isMulti
        isClearable
        classNamePrefix='registration-select'
        placeholder=""
        defaultValue={{value:"",label:""}}
        value={value}
        onChange={(option) => {
          if(option && option[option.length - 1].label == "None") {
            option = [{label:"None", value:"None"}]
          }
          else if(option && option[option.length - 1].label != "None") {
            option = option.filter(x=> x.label != "None")
          }
          const { user } = formikProps.values;
          user[name] = option ? option.map(x=>x.label) : []
          formikProps.setFieldValue('user', user)
        }}
      />
      {renderOptions.other && value && value.filter(x=>x.label=="Other").length > 0 ? (
        <><br/>{this.renderField(`${name}other`, formikProps, false, {placeholder: 'Other'})}</>
      ) : ""}
      </>
    )
  }

  customFilter(option, searchText) {
    const ones =  option.label.toLowerCase().split(" ").filter(x=> x.startsWith(searchText.toLowerCase()));
    return ones.length > 0;
  }

  renderPopout() {
    const { popoutIsOpen } = this.state;
    if (popoutIsOpen) {
      return (
        <div className="reg-popout-box">
          <img className="icon-vax" onClick={this.togglePopout} src="/registration/images/icon-vax.svg" />
          <div className="icon-exit" onClick={this.togglePopout}>×</div>
          A person is considered fully vaccinated: fourteen days after their second dose in a two-dose series, such as the Pfizer and Moderna vaccines, or fourteen days after a single-dose vaccine, such as the Johnson & Johnson vaccine. This guidance applies to COVID-19 vaccines authorized for use by the U.S. Food and Drug Administration (i.e., Pfizer-BioNTech, Moderna, and Johnson and Johnson (J&J)/Janssen COVID-19 vaccines), and COVID-19 vaccines authorized for emergency use by the World Health Organization (i.e., AstraZeneca/Oxford, Sinopharm, Sinovac, Covaxin, Covovax and Nuvaxovid). The full list of vaccines with final WHO EUL/PQ evaluation status is available <a href="https://extranet.who.int/pqweb/sites/default/files/documents/Status_COVID_VAX_02March2022.pdf" target="_blank">here</a>.
        </div>
      );
    }
    return '';
  }

  renderPageOne(formikProps) {
    const { fields, participant } = this.props;
    const { values } = formikProps;
    const { pageOneError, emailTaken, checkError, pageThreeError, fullPageError } = this.state;
    return (
      <>
      <div className={`registration-page-one container ${this.pageHiddenClass(1)}`}>
        <div className="row">
        <div className="col-xs-12 att-proam-registration-section-header">
            Your Information
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> First Name </label>
            </div>
            {this.renderField('name_first',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Last Name </label>
            </div>
            {this.renderField('name_last',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Email Address </label>
            </div>
            {this.renderField('email',formikProps,false,{disabled: true})}
            {emailTaken ? (
              <div className="registration-field-error">
                 This email has already been taken.
              </div>
            ): ""}
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Mobile Phone Number </label>
            </div>
            {this.renderField('work_phone',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Job Title </label>
            </div>
            {this.renderField('job_title',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Company </label>
            </div>
            {this.renderField('company',formikProps)}
          </div>
          {/* <div className="col-xs-12 col-md-6">
            <div className="label-wrapper">
              <label className="required label-english"> Country </label>
            </div>
            {this.renderSelectField('address_country',formikProps, getCountrySelectOptions())}
          </div> */}

          <div className="col-xs-12 att-proam-registration-section-copy">
            <br />
            Would you like to include your assistant on future communications regarding your registration?
          </div>

          <div className="col-xs-12">
            {this.renderCheckbox('includeassistant',formikProps, false, {
              forceValue: "True"
            })}
            <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px", marginRight: "20px"}}>Yes</label>
            {this.renderCheckbox('includeassistant',formikProps, false, {
              forceValue: "False"
            })}
            <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>No</label>
          </div>

          <div className="col-xs-12">
          </div>
          {values.user.includeassistant == "True" ? (
            <>
              <div className="col-xs-12 col-sm-6">
                <div className="label-wrapper">
                  <label className="required label-english"> Assistant Name </label>
                </div>
                {this.renderField('assistantname',formikProps)}
              </div>
              <div className="col-xs-12 col-sm-6">
                <div className="label-wrapper">
                  <label className="required label-english"> Assistant Email </label>
                </div>
                {this.renderField('assistantemail',formikProps)}
              </div>
            </>
          ):""}

          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>

          <div className="col-xs-12 att-proam-registration-section-header">
            Emergency Contact Information
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Emergency Contact First Name </label>
            </div>
            {this.renderField('ecfname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Emergency Contact Last Name </label>
            </div>
            {this.renderField('eclname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Emergency Contact Phone Number </label>
            </div>
            {this.renderField('ecnumber',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="label-wrapper">
              <label className="required label-english"> Relation to Emergency Contact </label>
            </div>
            {this.renderField('ecrelation',formikProps)}
          </div>

          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>

          <div className="col-xs-12 att-proam-registration-section-header">
            Guest Information
          </div>
          <div className="col-xs-12 att-proam-registration-section-copy">
            <br />
            I would like to bring a guest.
            <br />
            Please Note: Guests must be 21 years of age or older.
          </div>
          <div className="col-xs-12">
            {this.renderCheckbox('bringingguest',formikProps, false, {
              forceValue: "True"
            })}
            <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px", marginRight: "20px"}}>Yes</label>
            {this.renderCheckbox('bringingguest',formikProps, false, {
              forceValue: "False"
            })}
            <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>No</label>
          </div>
          {this.renderGuestFields(formikProps)}

          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>

          <div className="col-xs-12 att-proam-registration-section-header">
            Confirmation of Compliance
          </div>
          <div className="col-xs-12 att-proam-registration-section-copy">
            Cisco’s Global Compliance Team asks that you confirm that you are permitted by your company to accept this ticket(s) in accordance with your company’s internal expectations and policies.
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('confirmationofcompliance',formikProps)}
            <label className="checkbox-label required" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>I confirm</label>
            <div className="registration-field-error">
              {checkError && !this.isPageOneValid(formikProps) && values.user.confirmationofcompliance != "True" ?
                <>Please click to confirm.</>
                :
                ""
              }
            </div>
          </div>

          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>

          <div className="col-xs-12 att-proam-registration-section-header">
            Event Release and Code of Conduct
          </div>
          <div className="col-xs-12 att-proam-registration-section-copy">
            Do you agree to the <a href="/registration/GCF-NYC23_EventRelease.pdf" target="_blank" style={{textDecoration: "underline"}}>Waiver and Release</a>?
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('termsandconditions',formikProps)}
            <label className="checkbox-label required" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>I agree</label>
            <div className="registration-field-error">
              {checkError && !this.isPageOneValid(formikProps) && values.user.termsandconditions != "True" ?
                <>Please click to confirm.</>
                :
                ""
              }
            </div>
          </div>

          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>

          <div className="col-xs-12 att-proam-registration-section-header">
            Health and Safety
          </div>
          <div className="col-xs-12 att-proam-registration-section-copy">
            The health and safety of event attendees is our top priority. If you are feeling unwell, please refrain from attending the event. At this time, Cisco does not have any health-related requirements in place for event attendance. Should this change, we will follow up with updated guidance.
          </div>

          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {checkError && !this.isPageOneValid(formikProps) ?
                <div dangerouslySetInnerHTML={{__html: pageOneError}}></div>
                :
                ""
              }
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }

  renderPageTwo(formikProps) {
    const { participant } = this.props;
    return (
      <div className={`registration-page-one container ${this.pageHiddenClass(2)}`}>
        <div className="row">
          <div className="col-xs-12 att-proam-registration-header-copy" style={{textAlign: "left"}}>
            <span style={{fontSize: "24px"}}>
              <>
                Thank you for registering to join Cisco at the 2023&nbsp;Global&nbsp;Citizen&nbsp;Festival.
              </>
            </span>
            <br/>
          </div>

          <div className="col-xs-12 att-proam-registration-section-copy" style={{color: "#000000", textAlign: "left", fontSize: "18px", lineHeight: "23px"}}>
            <br/>
            We are delighted you will join us for this exclusive experience. We have sent a confirmation email to {" "+participant['email']}.
            <br />
            <br />
            If you have any questions, please contact the Cisco Executive Events Team.
            <br />
            <br />
            <div style={{marginTop: "10px", textAlign: "center"}}>
              <a target="_blank" href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202023%20Global%20Citizen%20Festival">
                <button type="button" style={{paddingTop: "10px", marginBottom: "20px"}} className="att-proam-button att-proam-submit-button">
                  Contact Us
                </button>
              </a>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    )

  }

  renderHeader(formikProps) {
    const { page } = this.state;
    if (page === 2) return <></>;
    return (
      <div className="att-proam-registration-header container">
        <div className="row">
          <div className="col-xs-12">
            <div className="att-proam-registration-header-copy" style={{textAlign: "left"}}>
              <span style={{fontSize: "18px"}}>
                <>
                  Join Cisco for the Ultimate VIP experience at the 2023 Global Citizen Festival in New York City. The gathering is a celebration featuring artists and global leaders committed to ending extreme poverty, ensuring the future health of our planet, and powering an inclusive future for all.
                </>
              </span>
              <br/>
              <br/>
              Please note that your invitation to attend the Global Citizen Festival is non-transferable. If you have any questions regarding the registration process, please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202023%20Global%20Citizen%20Festival">executiverelations@cisco.com</a>.
            </div>
          </div>

          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>

          <div className="att-proam-registration-header-copy">
            <div style={{fontSize: "18px", marginBottom: "8px"}}>Global Citizen Festival</div>
            <div className="att-proam-registration-header-copy-date">
              Saturday, September 23
              <br/>
              4:00 p.m. ET
              <br />
              Great Lawn, Central Park · New York City
            </div>
          </div>
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
        </div>
      </div>
    )
  }

  renderButtons(formikProps) {
    const { page } = this.state;
    if(page < 2) {
      return (
        <div className="registration-page-one container visible">
          <div className="row">
            <div className="col-xs-12">
              <br/>
            </div>
            <div onClick={()=>this.nextPage(formikProps)} className="col-xs-6 text-left">
              <button className="att-proam-button att-proam-submit-button reg-button">
                {page == 1 ? <>Register</> : <>Next Step</>}
              </button>
            </div>
            <div className="col-xs-12">
              <br/>
              <br/>
            </div>
          </div>
        </div>
      )
    }
  }

  nextPage(formikProps = {}){
    const { page }  = this.state;
    switch(page) {
      case 1:
        if(!this.isPageOneValid(formikProps)){
          this.setState({
            pageOneError: "Please make sure all fields have been properly filled in.</i>",
            checkError: true,
          })
          return;
        }else {
          //TODO send request
          formikProps.submitForm();
          return;
        }
        break;
      case 2:
        return;
      default:
        break;
    }

    window.scrollTo(0,0);
    console.log(`page to: ${page + 1}`);
    this.setState({
      page: page + 1,
      checkError: false,
    })
  }

  prevPage(formikProps){
    const { page }  = this.state;
    window.scrollTo(0,0);
    if(page == 3) {
      this.setState({
        page: 1,
        fullPageError: null,
        checkError: false
      })
      return;
    }
    this.setState({
      page: page - 1,
      fullPageError: null,
      checkError: false
    })
  }

  render() {
    const { user, participant} = this.props;
    // console.log('overnight program');
    console.log(this.state);
    return (
      <div className="registration-form">
        {this.renderTester()}
        <Formik
          initialValues={{
            user: {
              attendeetype: "Customer",
              tickettype: "UVIP",
              name_first: participant ? participant.name_first : "",
              name_last: participant ? participant.name_last : "",
              email: participant ? participant.email : "",
              work_phone: "",
              job_title: "",
              company: "",
              // address_country: "",
              includeassistant: "",
              assistantname: "",
              assistantemail: "",
              ecfname: "",
              eclname: "",
              ecnumber: "",
              ecrelation: "",
              bringingguest: "",
              guestageconfirm: "",
              guestfname: "",
              guestlname: "",
              confirmationofcompliance: "",
              termsandconditions: "",
              attendeesource: "Natural Registration"
            },
          }}

          onSubmit={(values, actions) => {
            submitRegistration(values, this.state, this)
          }}
          render={(formikProps) => (
            <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="user-registration-form" style={{border: "0"}}>
              {this.renderHeader(formikProps)}
              {this.renderPageOne(formikProps)}
              {this.renderPageTwo(formikProps)}
              {this.renderButtons(formikProps)}
            </Form>
        )}/>
      </div>
    );
  }
}

export default RegistrationForm;
