import React from "react";
import PropTypes from "prop-types";
import MaterialTable from 'material-table';

class GeneralTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: null,
    };
  }

  getColumns() {
    const { columns } = this.props;
    return columns || [];
  }

  getActions() {
    const { actions } = this.props;
    return actions || null;
  }

  getData() {
    const { data, filters } = this.props;
    const { selectedFilter } = this.state;
    if(filters) {
      const filter = filters.find(x=> x.id == selectedFilter)
      if(filter && filter.filterFunction) {
        return data.filter((x,i,t)=>filter.filterFunction(x,i,t))
      }
    }
    return data || [];
  }

  getTitle() {
    const { title } = this.props;
    return title;
  }

  renderTable() {
    const {selectedFilter}  = this.props;
    return (
      <MaterialTable
        key={`material-table-selected-table-${selectedFilter}`}
        options={{
          paging: true,
          emptyRowsWhenPaging: false
        }}
        columns={this.getColumns()}
        data={this.getData()}
        autoHeight={false}
        pageSize="100"
        actions = {this.getActions()}
        title={this.getTitle() || ""}
      />
    )
  }

  countFilter(filter) {
    const { data } = this.props;
    if(filter && filter.filterFunction)  {
      return data.filter((x,i,t)=> filter.filterFunction(x,i,t)).length;
    }
    return 0;
  }


  renderFilters() {
    /*
    * Scheme
    * id: string
    * name: string
    * filterFunction: function for enumerable
    */
    const { selectedFilter } = this.state;
    const { filters } = this.props;

    if(filters) {
      const renderedFilters = filters.map(filter=>(
        <div key={`cnt-default-layout-general-table-filter-${filter.id}`} className={`${selectedFilter == filter.id ? "active" : ""} cnt-default-layout-general-table-filter`} onClick={() => this.setState({selectedFilter: filter.id})}>
          {filter.name} ({this.countFilter(filter)})
        </div>
      ))
      return (
        <div className="cnt-default-layout-general-table-filters-container">
          {renderedFilters}
        </div>
      )
    }
    return (
      <div className="cnt-default-layout-general-table-filters-container">
        <div className="cnt-default-layout-general-table-filter">
          {" "}
        </div>
      </div>
    )
  }

  render() {
    const { user } = this.props;
    return (
      <div className="cnt-default-layout-general-table-container">
        {this.renderFilters()}
        {this.renderTable()}
      </div>
    );
  }
}

export default GeneralTable;
