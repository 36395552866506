import React from "react";
import PropTypes from "prop-types";
import ReportBubble from "./ReportBubble"
import EmailSectionStats from "./mail/EmailSectionStats"
import { uuidv4 } from '@utility/FileUtils'
import DefaultTooltip from "@layout/tooltips/DefaultTooltip"

class EmailSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: uuidv4()
    }
  }

  renderToolTips() {
    return (
      <>
      <DefaultTooltip
        tooltipId={this.getTooltipId()+"download-report"}
      >
        Click to download full report including CC and BCC info
      </DefaultTooltip>
        <DefaultTooltip
          tooltipId={this.getTooltipId()+"open-rate"}
        >
          Opens count only invitees, no CCs or BCCs
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={this.getTooltipId()+"opened"}
        >
          Opens count only invitees, no CCs or BCCs
        </DefaultTooltip>

        <DefaultTooltip
          tooltipId={this.getTooltipId()+"click-rate"}
        >
          Clicks count only invitees, no CCs or BCCs
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={this.getTooltipId()+"clicked"}
        >
          Clicks count only invitees, no CCs or BCCs
        </DefaultTooltip>

        <DefaultTooltip
          tooltipId={this.getTooltipId()+"bounce-rate"}
        >
          Bounces count only invitees, no CCs or BCCs
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={this.getTooltipId()+"bounced"}
        >
          Bounces count only invitees, no CCs or BCCs
        </DefaultTooltip>

        <DefaultTooltip
          tooltipId={this.getTooltipId()+"registered"}
        >
          Conversion number from only this type of email
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={this.getTooltipId()+"declined"}
        >
          Conversion number from only this type of email
        </DefaultTooltip>
      </>
    )

  }

  filterMainRecipients(activities, mainRecipients) {
    return activities.filter(x=> mainRecipients.indexOf(x.recipient) != -1)
  }

  getTooltipId() {
    return "sg-admin-email-section-tooltip-"+this.state.id+"-"
  }

  renderStatistic(label, value, opts = {}) {
    const style = {}
    if(opts.color){
      style.color = opts.color
    }
    return (
      <div
        data-tip
        data-for={this.getTooltipId()+(opts.id||"")}
        className="sg-admin-email-section-statistic-section"
      >
        <div className="sg-admin-email-section-statistic"
          style={style}
        >
          {value}
          <div className="sg-admin-email-section-statistic-label">
            {label}
            {opts.report ? (
              <div className="sg-admin-panel-statistic-download">
                <a data-tip data-for={this.getTooltipId()+"download-report"} href={opts.report} >
                  <img src="/images/admin/blue-download-report.png"/>
                </a>
              </div>
            ):""}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { bubbleLabel, email, declines, registrants, supressRegistration} = this.props;
    const { activity } = email;
    const recipients = activity.map(x=>x.emails).flat()
    const mainRecipients = recipients.map(x=>x.email);
    const cc_emails = recipients.map(x=>(x.cc_list||"").split(",")).flat();
    const total_recipients = mainRecipients.concat(cc_emails).length

    const activities = activity.map(x=>x.activity).flat()
    const clicks = this.filterMainRecipients(
      activities.filter(x=>x.activity_type=="clicked"),
      mainRecipients
    ).map(x=>x.recipient).filter((x,i,t)=> t.indexOf(x) == i);

    const mainOpens = this.filterMainRecipients(
      activities.filter(x=>x.activity_type=="opened"),
      mainRecipients
    ).map(x=>x.recipient).filter((x,i,t)=> t.indexOf(x) == i);

    const mainBounces = this.filterMainRecipients(
      activities.filter(x=>x.activity_type=="bounced"),
      mainRecipients
    ).map(x=>x.recipient).filter((x,i,t)=> t.indexOf(x) == i);


    const newRegistrants = registrants.filter(x=> mainRecipients.indexOf(x.email) != -1)
    const newDeclines = declines.filter(x=> mainRecipients.indexOf(x.email) != -1)



    const mainOpenRateCopy = mainRecipients.length > 0 ? ((mainOpens.length/mainRecipients.length)*100).toFixed(0)+"%" : "N/A"
    const clickRateCopy = mainRecipients.length > 0 ? ((clicks.length/mainRecipients.length)*100).toFixed(0)+"%" : "N/A"
    const bounceRateCopy = mainRecipients.length > 0 ? ((mainBounces.length/mainRecipients.length)*100).toFixed(0)+"%" : "N/A"
    return (
      <div className="row">
        {this.renderToolTips()}
        <div className="col-xs-4 col-md-2">
          <ReportBubble
            title={bubbleLabel||"Invitees"}
            count={mainRecipients.length}
            color="#2050A9"
            bubbleTooltip="All emails received, main recipients only, no CCs or BCCs"
            background="#2050A9"
            url={`/admin/reports/mailing_list_report_by_tag?tag=${email.id}`}
          />
        </div>
        <div className="col-xs-1"/>

        <div className="col-xs-4 col-md-2">
          {this.renderStatistic("Open Rate", mainOpenRateCopy, {id: "open-rate",
            report:`/admin/reports/activity_report_by_tag?tag=${email.id}&type=opened`
          })}
          {this.renderStatistic("Opened", mainOpens.length, {small: true, id: "opened"})}
        </div>

        <div className="col-xs-4 col-md-2">
          {this.renderStatistic("Click Rate", clickRateCopy, {
            id: "click-rate",
            report:`/admin/reports/activity_report_by_tag?tag=${email.id}&type=clicked`
          })}
          {this.renderStatistic("Clicked Any Link", clicks.length, {small: true, id: "clicked"})}
        </div>
        <div className="col-xs-4 col-md-2">
          {this.renderStatistic("Bounce Rate", bounceRateCopy, {
            id: "bounce-rate",
              report:`/admin/reports/activity_report_by_tag?tag=${email.id}&type=bounced`
          })}
          {this.renderStatistic("Bounced", mainBounces.length, {small: true, id: "bounced"})}
        </div>
        {supressRegistration == true ? "" : (
          <>
            <div className="col-xs-4 col-md-2"  style={{color: "#74BF4B"}}>
              {this.renderStatistic("Registered", newRegistrants.length, {id: "registered", color: "#74BF4B"})}
            </div>
            <div className="col-xs-4 col-md-1" style={{color: "#828993"}}>
              {this.renderStatistic("Declined", newDeclines.length, {id: "declined", color: "#828993"})}
            </div>
          </>
        )}

        <div className="col-xs-12 sg-admin-email-statistic-warning">
          * Invitees are email main recipients that are reported back by their email provider, who may provide incorrect information, especially in the case of bounces.
        </div>
      </div>
    )
  }
}

export default EmailSection;
