import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import Select from 'react-select'
class RegistrationEmailValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      error: null,
      emailValue: "",
      showDecline: false,
      showRegistered: false
    }
    this.verifyEmail = this.verifyEmail.bind(this);
  }


  verifyEmail(e) {
    e.preventDefault()
    const { features } = this.props;
    const { emailValue, selectedDate} = this.state;
    if(features &&  features.pick_a_date) {
      if(selectedDate == null) {
        this.setState({
          error: "Please select a program to register for."
        })
        return;
      }
    }
    fetchAPI(`/registration/verify_email`,(json)=>{
      let error = null;
      let attendee = null;
      let declined = null;
      let registered = null;
      if(json){
        attendee = json.attendee;
        error = json.error;
        declined = json.declined;
        registered = json.registered;
      }else {

      }

      if( error ) {
        console.log({error})
        if (declined) {
          this.setState({
            showDecline: true,
            showRegistered: false,
            error: error
          });
        } else if (registered) {
          this.setState({
            showDecline: false,
            showRegistered: true,
            error: error
          });
        } else {
          this.setState({
            error: error
          });
        }

      } else if (!error && attendee) {
        window.location = `/registration?id=${attendee}${features.pick_a_date ? "&ticket="+selectedDate.value:""}${features.sandbox ? "&sandbox=true":""}`
      }else {

      }
    } ,{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }

  renderPickADate() {
    const { features } = this.props;
    const { selectedDate } = this.state;
    const options = [
      {value: 'day-5', label: 'Sunday, February 6, 2022'}
    ]
    if(features && features.pick_a_date) {
      return (
        <>
          <label>Please select the day you would like to join us from the dropdown below.</label>
          <div style={{maxWidth:"600px",margin: "auto"}}>
            <Select
              options={options}
              classNamePrefix='registration-select'
              value={selectedDate}
              onChange={(option) => {
                this.setState({
                  selectedDate: option
                })
              }}
            />
          </div>
          <br/>
        </>
      )
    }
  }

  render() {
    const { emailValue, error, showDecline, showRegistered } = this.state;
    return (
      <form onSubmit={this.verifyEmail} className="att-proam-registration-email-validation registration-form ">
        <div className="att-proam-registration-email-validation-copy">
          <span style={{fontSize: "24px"}}>
            We look forward to seeing you in New York City for the 2023&nbsp;Global Citizen Festival.
          </span>
          <br/>
          <br/>
          <span style={{fontSize: "24px"}}>
            As this is an invitation-only event, please ensure the email address entered below is that of the registrant.
          </span>
        </div>
        <div className="att-proam-registration-email-validation-fields">
          <br/>
          <div className="label-wrapper">
            <label className="label-english">Enter Email Address</label>
          </div>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="form-control form-control-no-margin"
          />
          <br/>
          {this.renderPickADate()}
        </div>
        {error ? (
          <div className="att-proam-registration-email-validation-error" dangerouslySetInnerHTML={{__html: error}}></div>
        ):""}
        {
          showDecline ?
          <div className="att-proam-registration-email-validation-error" style={{padding: "0px 0px"}}>
            You have previously declined, please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202023%20Global%20Citizen%20Festival">Cisco Executive Events</a> to update your registration status.
          </div>
          :
          ""
        }
        {
          showRegistered ?
          <div className="att-proam-registration-email-validation-error" style={{padding: "0px 0px"}}>
            You’re already registered! If you wish to make changes to your registration, please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202023%20Global%20Citizen%20Festival">Cisco Executive Events</a>
          </div>
          :
          ""
        }
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <button
            type="button"
            onClick={this.verifyEmail}
            className="att-proam-button att-proam-submit-button"
          >
            Continue
          </button>
          <br />
          <br />
          <br />
        </div>
      </form>
    );
  }
}

export default RegistrationEmailValidation;
