import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"
import Table from "rc-table";
import "chart.js";
import { LineChart, AreaChart, ColumnChart, BarChart } from "react-chartkick";
import GeneralTable from "./GeneralTable";
import AdminOpenablePanel from "./platform_reports/AdminOpenablePanel";
import AdminParticipantModal from "./ticketing/forms/AdminParticipantModal"
import ReportBubble from "./platform_reports/ReportBubble"
import RegisteredOverTimeModal from "./platform_reports/RegisteredOverTimeModal"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip"
import MailActivityReports from "./platform_reports/mail/MailActivityReports"
import EmailSectionStats from "./platform_reports/mail/EmailSectionStats"
import EmailSection from "./platform_reports/EmailSection"
import AddAdminSection from "./admins/AddAdminSection"
import TicketsDashboard from "./ticketing/TicketsDashboard"
import { fetchAPI } from "@utility/NetworkUtils"

class UsersDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      googleDwellJson: null,
      ticketTypes: props.ticket_info,
      participants:  props.participants
    }
    this.updateTicket = this.updateTicket.bind(this);
    this.updateAttended = this.updateAttended.bind(this);
    this.renderAttendedCheckbox = this.renderAttendedCheckbox.bind(this);
    this.updateParticipantCallback = this.updateParticipantCallback.bind(this);
  }

  updateParticipantCallback(new_participants) {
    this.setState({
      participants: new_participants
    })
  }

  colorPalatte() {
    return [
      "#1FBDEB",
    ]
  }

  updateTicket(ticket, count, name) {
    let { ticketTypes } = this.state;
    ticket.count = count;
    ticket.name = name;
    ticketTypes.filter(x=>x.id != ticket.id).concat([ticket]);
    this.setState({
      ticketTypes
    })
    fetchAPI(`/admin/ticketing/${ticket.id}`,(json)=>{
      console.log(json);
    },{
      method:'PATCH',
      body:{ticket_type: {count: count, name: name}}
    });
  }

  getRegistrantsByRegion() {
    let {participants} = this.state;
    participants = participants.filter(x=>x.email.indexOf("@jaguardesignstudio") == -1);

    const attendee_types = participants.map(x=> x['address_country']).filter((x,i,t) => t.indexOf(x) == i);

    let attendee_object = attendee_types.filter(x=>x).map(x=>
      [x,participants.filter(y=> y['address_country'] == x).length]
    )
    return attendee_object;
  }

  getTotalRegistrants() {
    return this.getFilteredRegistrants().length;
  }

  getTotalRegistrantsPath1() {
    return this.getFilteredRegistrants().filter(x=>x.registration_answers.programname == "Overnight");
  }
  getTotalRegistrantsPath3() {
    return this.getFilteredRegistrants().filter(x=>(x.registration_answers.programname||"").indexOf("Day")!=-1);
  }
  getTotalRegistrantsPath2() {
    return this.getFilteredRegistrants().filter(x=>x.registration_answers.programname == "Overnight - No Hotel");
  }

  getFilteredParticipants(opts={}) {
    const { participants } = this.state;
    return participants.filter(x=> !x.test_flag)
  }

  getFilteredRegistrants(opts={}) {
    const { participants } = this.state;
    return this.getFilteredParticipants(opts).filter(x=>x.status == "registered")
  }
  getFilteredDeclines(opts={}) {
    const { participants } = this.state;
    return this.getFilteredParticipants(opts).filter(x=>x.status == "declined")
  }

  renderBubbles() {
    const { invitees, invites_sent, surveys } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12" style={{verticalAlign: "top"}}>
          <div className="sg-platform-report-circle-container">
            <div className="sg-platform-report-circle" style={{backgroundColor:"#0D274D"}}>
              <div className="sg-platform-report-circle-inner">
                <div className="sg-platform-report-circle-title">
                  Total
                  <br/>
                  Preregistered
                </div>
                <div className="sg-platform-report-circle-count">
                  {this.getFilteredParticipants().filter(x=> x.status === "preregistered").length}
                </div>
              </div>
              <a href="/admin/reports/preregistered_report" className="sg-platform-report-download-link">
                Download Report
              </a>
            </div>
          </div>
          <div className="sg-platform-report-circle-container">
            <div className="sg-platform-report-circle" style={{backgroundColor:"#0D274D"}}>
              <div className="sg-platform-report-circle-inner">
                <div className="sg-platform-report-circle-title">
                  Total
                  <br/>
                  Registrations
                </div>
                <div className="sg-platform-report-circle-count">
                  {this.getFilteredParticipants().filter(x=> x.status === "registered").length}
                </div>
              </div>
              <a href="/admin/reports/registered_report_path_3" className="sg-platform-report-download-link">
                Download Report
              </a>
            </div>
          </div>
          <div className="sg-platform-report-circle-container">
            <div className="sg-platform-report-circle" style={{backgroundColor:"#0D274D"}}>
              <div className="sg-platform-report-circle-inner">
                <div className="sg-platform-report-circle-title">
                  Total
                  <br/>
                  Declined
                </div>
                <div className="sg-platform-report-circle-count">
                  {this.getFilteredParticipants().filter(x=> x.status === "declined").length}
                </div>
              </div>
              <a href="/admin/reports/preregistered_report" className="sg-platform-report-download-link">
                Download Report
              </a>
            </div>
          </div>
          <br/>
          <br/>
        </div>
        <br/>
      </div>
    )
  }

  renderParticipants() {
    return (
      <div className="row">
        <div className="col-xs-3 col-sm-5ths">
          <ReportBubble
            title={"Preregistered"}
            bubbleTooltip={"# of all people pre-registered in the database"}
            count={this.getFilteredParticipants().filter(x=>x.status =="preregistered").length}
            url="/admin/reports/preregistered_report"
          />
        </div>
        <div className="col-xs-3 col-sm-5ths">
          <ReportBubble
            title={"Registered"}
            bubbleTooltip={"# of all people that registered either via broadcast email, individual send or were entered manually"}
            count={this.getFilteredParticipants().filter(x=>x.status =="registered").length}
            url="/admin/reports/registered_report"
            background="#74BF4B"
          />
        </div>
        <div className="col-xs-3 col-sm-5ths">
          <ReportBubble
            title={"Declined"}
            bubbleTooltip={"# of all customers that declined either via broadcast email, individual send or were entered manually"}
            count={this.getFilteredParticipants().filter(x=>x.status =="declined").length}
            url="/admin/reports/declined_report"
            background="#818181"
          />
        </div>
        <div className="col-xs-3 col-sm-5ths">

        </div>
      </div>
    )
  }

  renderAttendeeTypes() {

    let customerCount = 0;
    let eltCount = 0;
    let otherCount = 0;

    this.getFilteredParticipants().forEach(x => {
      if (x.status =="registered") {
        if (x.registration_answers.attendeetype=="Customer") {
          customerCount = customerCount + 1;
          if (x.registration_answers.bringingguest == "True") {
            customerCount = customerCount + 1;
          }
        }
        if (x.registration_answers.attendeetype=="Cisco ELT / SLT") {
          eltCount = eltCount + 1;
          if (x.registration_answers.bringingguest == "True") {
            eltCount = eltCount + 1;
          }
        }
        if (x.registration_answers.attendeetype!=="Customer" && x.registration_answers.attendeetype!=="Cisco ELT / SLT") {
          otherCount = otherCount + 1;
          if (x.registration_answers.bringingguest == "True") {
            otherCount = otherCount + 1;
          }
        }
      }
    });    

    return (
      <div className="row">
        <div className="col-xs-3 col-sm-5ths">
          <ReportBubble
            title={"Customer"}
            bubbleTooltip={"# of registered customers"}
            count={customerCount}
            background="#74BF4B"
          />
        </div>
        <div className="col-xs-3 col-sm-5ths">
          <ReportBubble
            title={"Cisco ELT/SLT"}
            bubbleTooltip={"# of registered Cisco ELT/SLT"}
            count={eltCount}
            background="#74BF4B"
          />
        </div>
        <div className="col-xs-3 col-sm-5ths">
          <ReportBubble
            title={"Other"}
            bubbleTooltip={"# of registered others"}
            count={otherCount}
            background="#74BF4B"
          />
        </div>
      </div>
    );
  }

  renderCheckbox(value) {
    if(value) {
      return (
        <img src="/images/admin-checkmark.svg" className="sg-admin-table-checkbox" />
      )
    }
  }

  getSmallStyle() {
    return {
      width: 30,
      maxWidth: 30,
      textAlign: "center",
    }
  }

  getTableRegistrants() {
    const { invites_sent, surveys } = this.props;
    let participants = this.getFilteredParticipants();
    participants = participants.map(attendee=>{
      attendee.lower_first_name = attendee && attendee.name_first ? attendee.name_first.toLowerCase() : "";
      attendee.lower_last_name = attendee && attendee.name_last ? attendee.name_last.toLowerCase() : "";
      attendee.lower_company = attendee && attendee.company ? attendee.company.toLowerCase() : "";
      attendee.registered = true;
      attendee.attended = attendee.registration_answers.attended;
      attendee.evaluation = surveys.map(x=> x.uuid).indexOf(attendee.uuid) != -1;
      attendee.ticket_type = attendee.registration_answers.programname;
      return attendee;
    })
    return participants;
  }

  didUserAttend(attendee) {
    return false;
  }

  getTableColumns() {
    const { user } = this.props;
    // { title: 'Evaluation', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'evaluation', sorting: true, render: (row) => this.renderCheckbox(row.evaluation)},
    // { title: 'Attended', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'attended', sorting: true, render: (row) => this.renderCheckbox(row.attended == true)},

    let allColumns = [
      { title: 'First', field: 'lower_first_name', render: (row) => row.name_first},
      { title: 'Last', field: 'lower_last_name', render: (row) => row.name_last},
      { title: 'Email', field: 'email', render: (row) => row.email},
      { title: 'Company', field: 'lower_company', render: (row) => row.company },
      { title: 'Ticket Type', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'ticket_type', sorting: true},
      { title: 'Status', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'status', sorting: true},
      { title: 'Attended Event', field: 'attended_event', render: this.renderAttendedCheckbox},
      { title: 'Registered', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'status', sorting: true, render: (row) => this.renderCheckbox(row.status == "registered")},
      { title: 'Declined', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'status', sorting: true, render: (row) => this.renderCheckbox(row.status == "declined")},
    ]
    return allColumns;
  }

  renderAttendedCheckbox(row) {
    let value = row.attended;
    return(
      <img
        src={ value === "True" ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          this.updateAttended(row, value === "True" ? "False" : "True")
        }}
      />
    )
  }

  updateAttended(row, value) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/admin/ticketing/update_user`, {
      method: `POST`,
      redirect: "manual",
      body: JSON.stringify({
        gid: row.gid,
        user: {
          email: row.email,
          attended: value
        }
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    }).then(response => {
      return response.json();
    }).then(json => {
      if (json.error == null) {
        this.updateParticipantCallback(json.participants)
      }
    });
  }

  renderDwellTimeReport() {
    const { googleDwellJson } = this.state;
    const tiles = [
      {name: "Dashboard", url:"/", averageTime:0},
      {name: "Agenda", url:"/agenda", averageTime:0},
      {name: "Session Catalog", url:"/pages/meetings", averageTime:0},
      {name: "Partners", url:"/partners", averageTime:0},
      {name: "Speakers", url:"/speakers", averageTime:0},
      {name: "Film Festival", url:"/film_festival", averageTime:0},
      {name: "Session Replays", url:"/session_replays", averageTime:0},
      {name: "Resources", url:"/resources", averageTime:0},
      {name: "Surveys", url:"/surveys", averageTime:0},
      {name: "FAQ", url:"/info", averageTime:0},
    ]

    if(googleDwellJson &&  googleDwellJson.rows) {
      let stats = googleDwellJson.rows
      tiles.map(tile=> {
        const row = stats.find(y=> y[0] == tile.url)
        if(row) {
          tile.averageTime = parseFloat(row[4]).toFixed(0)
        }
        return tile;
      })

      const data_mapped = tiles.map(x => [x.name, x.averageTime])
      return (
        <div className="col-xs-12">
          <h1 className="sg-platform-report-graph-title"> Average Page Dwell Times</h1>
           <ColumnChart xtitle="Page" ytitle="Average Dwell Time (seconds)" color={["#1FBDEB"]} data={data_mapped} />
        </div>
      )
    }
  }

  columnChartDataSet() {
    return {
        borderColor:"rgba(31, 189, 235, 0.5)",
        backgroundColor: "rgba(31, 189, 235, 0.5)",
        lineTension: 0,
        fill: true,
      }
  }

  columnChartDataSetTotalReg() {
    return {
      borderColor: ["rgba(124, 124, 124, 0.5)", "rgba(31, 189, 235, 0.5)"],
      backgroundColor: ["rgba(124, 124, 124, 0.5)", "rgba(31, 189, 235, 0.5)"],
      fill: true,
    }
  }

  getTotalRegistrantsChartData() {
    const registrants = this.getFilteredRegistrants()
    return [
      ["Cisco Employee + Octagon", registrants.filter(x=> x.email.indexOf("@cisco.com") != -1 ||  x.email.indexOf("@octagon.com") != -1).length],
      ["Customer Total", registrants.filter(x=> x.email.indexOf("@cisco.com") == -1 &&  x.email.indexOf("@octagon.com") == -1).length]
    ]
  }

  getTotalCustomerTitleChartData() {
    const registrants = this.getFilteredRegistrants().filter(x=> x.email.indexOf("@cisco.com") == -1 &&  x.email.indexOf("@octagon.com") == -1)
    return registrants.map(x=> x.job_title).filter((x,i,t)=>t.indexOf(x) == i).map(x=>[x, registrants.filter(y=>y.job_title == x).length]);
  }

  getOverTime() {
    const registrants = this.getFilteredRegistrants();
    const startDate = new Date("03-13-2023");
    const currentDate = new Date();

    const datesAreOnSameDay = (first, second) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();

    let iDate = startDate; //Iterator Date
    const dateObject = {}
    while(iDate < currentDate) {
      dateObject[startDate] = registrants.filter(x=> (new Date(x.registered_at) <= iDate) || datesAreOnSameDay(new Date(x.registered_at), iDate) ).length
      iDate.setDate(iDate.getDate() + 1);
    }
    return dateObject
  }

  getRegisteredOverTimeButton(opts={}) {
    return (
      <>
      <DefaultTooltip
        tooltipId={"sg-admin-panel-registered-over-time"}
      >
        Click to view line graph
      </DefaultTooltip>
      <RegisteredOverTimeModal
        participants={this.getFilteredRegistrants(opts)}
      >
        <img
          data-tip
          data-for="sg-admin-panel-registered-over-time"
          style={{height:"40px"}}
          src="/images/admin/registered-over-time.png"
        />
      </RegisteredOverTimeModal>
      </>
    )
  }

  getEmailFullReportButton(key) {
    return (
      <a href={`/admin/reports/full_activity_report_by_tag?tag=${key}`} target="_blank">
       <img src="/images/admin/full-save-report.png" style={
         {
           height: "42px",
           marginTop: "-20px"
         }
       }/>
      </a>
    )
  }

  renderFrame() {
    const { emails, fields } = this.props;
    const { ticketTypes } = this.state;
    console.log(this.props);
    return (
      <div className="sg-admin-panel-container">
        <AdminOpenablePanel
          header="Participants"
          defaultOpen={true}
          forceOpen={true}
          button={
            <>
             {/* {this.getRegisteredOverTimeButton()} */}
             <AdminParticipantModal
                participants={this.getFilteredParticipants()}
                outerStyle={{display:"inline-block"}}
                ticketTypes={ticketTypes}
                fields={fields}
                updateParticipantCallback={this.updateParticipantCallback}
              >
                <button className="sg-admin-openable-panel-header-custom-button-button"> + Add Participant </button>
              </AdminParticipantModal>
            </>
          }
        >
          {this.renderParticipants()}
        </AdminOpenablePanel>
        <AdminOpenablePanel
          header="Registered by Attendee Type"
          defaultOpen={true}
        >
          {this.renderAttendeeTypes()}
        </AdminOpenablePanel>
        <AdminOpenablePanel
          header="Tickets allocated per day"
          defaultOpen={true}
        >
          <TicketsDashboard
            participants={this.getFilteredParticipants()}
            ticketTypes={ticketTypes}
            updateTicket={this.updateTicket}
          />
        </AdminOpenablePanel>
        {
          (emails.find(x=>x.id=="kbyg") !== undefined) ?
          <AdminOpenablePanel
            header="Email Statistics: KBYG"
            defaultOpen={true}
            button={this.getEmailFullReportButton("kbyg")}
            forceOpen={true}
          >
            <EmailSection
              email={emails.find(x=>x.id=="kbyg")}
              supressRegistration={true}
              registrants={[]}
              declines={[]}
            />
          </AdminOpenablePanel>
          :
          <></>
        }
        {
          (emails.find(x=>x.id=="survey") !== undefined) ?
          <AdminOpenablePanel
            header="Email Statistics: Survey"
            defaultOpen={true}
            button={this.getEmailFullReportButton("survey")}
            forceOpen={true}
          >
            <EmailSection
              email={emails.find(x=>x.id=="survey")}
              supressRegistration={true}
              registrants={[]}
              declines={[]}
            />
          </AdminOpenablePanel>
          :
          <></>
        }
        <div className="sg-platform-report-section col-xs-12">
          <h1 className="sg-platform-report-graph-title"> Attendee Status </h1>
          <GeneralTable
            columns={this.getTableColumns()}
            data={this.getTableRegistrants()}
          />
        </div>
        <div className="sg-platform-report-section col-xs-12">
          <AdminOpenablePanel
            header="Admins"
            defaultOpen={true}
          >
            <AddAdminSection user_email={this.props.user_email} />
          </AdminOpenablePanel>
        </div>

      </div>
      
    );

  }

  render() {
    return this.renderFrame();
  }
}

export default UsersDashboard;
