import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
class RegistrationDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailValue: "",
      success: false,
      date: null,
    }
    this.declineEmail = this.declineEmail.bind(this);
  }


  declineEmail(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/registration/decline`,(json)=>{
      if(json && json.error == null){
        this.setState({
          success: true,
          date: json.date,
        });
      }else if(json && json.error) {
        console.log('in here');
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }


  render() {
    const { emailValue, error, success, date} = this.state;
    if(success) {
      return (
        <div className="att-proam-registration-email-validation-copy">
          <div class="att-proam-registration-section-header">Sorry we’ll miss you.</div>
          <br/>
          Thank you for your response. We are sorry you are unable to attend the 2023 Global Citizen Festival. We look forward to welcoming you at a future Cisco event.
          <br/>
          <br/>
          If you have any questions, please contact the <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202023%20Global%20Citizen%20Festival">Cisco Executive Events Team</a>.
          <br/>
          <br/>
          <div className="text-center">
            <a target="_blank" href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202023%20Global%20Citizen%20Festival">
              <button type="button" style={{paddingTop: "10px"}} className="att-proam-button att-proam-submit-button">
                Contact Us
              </button>
            </a>
          </div>
        </div>
      )
    }
    return (
      <form src="#" onSubmit={this.declineEmail} className="att-proam-registration-email-validation registration-form ">
        <div className={`registration-page-one container`}>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-header text-left">
              Sorry we’ll miss you.
            </div>
            <div className="col-xs-12 att-proam-registration-section-copy text-left">
              We are sorry you are unable to attend the 2023 Global Citizen Festival. To confirm your response, please provide your email address below.
              <br/>
              <br/>
              We look forward to welcoming you at a future Cisco event.
              <br/>
            </div>
          </div>
        </div>
        <div className="att-proam-registration-email-validation-fields">
          <br/>
          <div className="label-wrapper">
            <label className="label-english">Enter Email Address *</label>
          </div>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="form-control"
          />
        </div>
        {error ? (
          <div className="att-proam-registration-email-validation-error error-red">
            {error}
          </div>
        ):""}
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <button
            type="button"
            onClick={this.declineEmail}
            className="att-proam-button att-proam-submit-button"
          >
            Continue
          </button>
        </div>
        <div className={`registration-page-one container`} style={{paddingTop: "30px"}}>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-copy text-left">
              <br/>
              If you have any questions, please contact the <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202023%20Global%20Citizen%20Festival">Cisco Executive Events Team</a>.
              <br/>
              <br/>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default RegistrationDecline;
