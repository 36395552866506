import Cookies from "universal-cookie";

export function submitRegistration(values, state, component) {
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  let form_data = new FormData();
  // component.setState({
  //   loading: true,
  // })

  // ["activities"].map(x=>
  //   values.user[x] = values.user[x].concat([values.user[x+"other"]])
  // )

  for ( var key in values['user'] ) {
    form_data.append(`user[${key}]`, values['user'][key]);
  }

  const count = values.user.bringingguest== "True" ? "2" : "1"
  component.props.ticket_info.map(x=> {
    values.user[x.slug] = count;
    form_data.append(`user[${x.slug}]`, count);
  })

  fetch(`/registration`, {
    method: `POST`,
    redirect: "manual",
    body: form_data,
    headers: {
      "X-CSRF-Token": token,
    }
  }).then(response => {
    try {
      return response.json();
    }
    catch(e) {
      component.setState({
        loading: false,
        error: "An error occurred. Please try again later."
      })
    }
  })
  .then(json => {
    if (json.error == null) {
      window.scrollTo(0,0);
      component.setState({
        page: 2,
        loading: false,
      })
    } else {
      if (json.error == "reg-closed") {
        location.href = 'pages/closed';
      }
      component.setState({
        fullPageError: json.error,
        loading: false,
      })
    }
  });
}
