import React from "react";
import PropTypes from "prop-types";
import TicketDashboardBubble from "./TicketDashboardBubble"
class TicketsDashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  renderTickets() {
    const { ticketTypes } = this.props;
    return ticketTypes.sort((a,b)=>a.id - b.id).map(x=>
      this.renderTicketType(x)
    )
  }

  renderTicketType(ticket) {
    const { updateTicket, participants} = this.props;
    return (
      <TicketDashboardBubble
        ticket={ticket}
        participants={participants}
        updateTicket={updateTicket}
      />
    )
  }

  renderTabs() {
    const { fields } = this.props;
  }

  render() {
    return (
      <div className="sg-tickets-dashboard-container row">
        {this.renderTabs()}
        {this.renderTickets()}
      </div>
    )
  }
}

export default TicketsDashboard;
