import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import Select from 'react-select'
import {programName, activities, preferredRoomType, travelInformation, getApparelSizeOptions,specialAccomodations, dietaryRestrictions, getCountrySelectOptions, getStateSelectOptions, getTimezoneOptions, getTimezoneInfo, getJobTypes}  from "@registration/RegistrationOptions"
class AdminParticipantForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  submitParticipant(values) {
    const { savedFormCallback, loading, ticketTypes } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    var form_data = new FormData();
    this.setState({
      loading: true,
    })
    ticketTypes.map(ticket=>{
      values.user[ticket.slug]=`${values.user[ticket.slug]}`
    })
    if(loading) {
      return
    }

    fetch(`/admin/ticketing/update_user`, {
      method: `POST`,
      redirect: "manual",
      body: JSON.stringify(values),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    }).then(response => {
      try {
        return response.json();
      }
      catch(e) {
        this.setState({
          loading: false,
          checkError: true,
          error: "An error occurred. Please try again later."
        })
      }
    })
    .then(json => {
      if (json.error == null) {
        window.scrollTo(0,0);
        this.setState({
          loading: false,
          checkError: false
        })
        if(savedFormCallback) {
          savedFormCallback(json.participants)
        }
      } else {
        this.setState({
          checkError: true,
          error: "An error occurred. Please try again later.",
          loading: false,
        })
      }
    });
  }

  renderSections(formikProps) {
    const sections = [
      {title:"Ticket Requestor", subtitle:"Please provide contact information of the person requesting tickets.", render: this.renderTicketRequestor(formikProps)},
      {title:"Number of Tickets", subtitle:"Select the number of tickets to be allocated.", render: this.renderTicketsAndProgram(formikProps)},
      {title:"Ticket Holder Information", subtitle:"Please select the ticket holder role associated with this request.", render: this.renderTicketHolderInformation(formikProps)},

      {title:"Ticket Holder Guest Information", subtitle:"", render: this.renderGuestInformation(formikProps)},

      // {title:"Travel", subtitle:"For planning purpose, how does the ticket holder intend to travel to the selected hosting program?", render: this.renderTravelSection(formikProps)},
      // {title:"Additional Ticket Holder Information", render: this.renderAdditionalTicketHolderInformationSection(formikProps)},
      {title:"Additional Notes (Optional)", render: this.renderNotesSection(formikProps)}
    ]

    return sections.map(section=> (
      <div className="sg-admin-ticketing-form-section">
        <div className="sg-admin-ticketing-form-section-header sg-registration-h3-copy ">
          {section.title}
          <br/>
        </div>
        <div className="sg-admin-ticketing-form-section-header sg-registration-large-copy">
          {section.subtitle}
          <br/>
          <br/>
        </div>
        <div className="sg-admin-ticketing-form-section-body">
          {section.render}
        </div>
      </div>
    ))
  }

  renderGuestInformation(formikProps) {
    const { fields } = this.props;
    const { values } = formikProps;
    const { user } = values;
    return (
      <div className="row">
        <div className="col-xs-12 sg-registration-large-copy">
          <br/>
          Will the ticket holder be bringing a guest? (Optional)
        </div>
        <div className="col-xs-12">
          {this.renderCheckbox('bringingguest',formikProps, {
            forceValue: "True"
          })}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px", marginRight: "20px"}}>Yes</label>
          {this.renderCheckbox('bringingguest',formikProps, {
            forceValue: "False"
          })}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>No</label>
        </div>
        {user.bringingguest == "True" ? (
          <>
            <div className="col-xs-12 col-sm-6">
              <label className="required">Guest First Name </label>
              {this.renderField('guestfname',formikProps)}
            </div>
            <div className="col-xs-12 col-sm-6">
              <label className="required">Guest Last Name </label>
              {this.renderField('guestlname',formikProps)}
            </div>
          </>
        ):""}

      </div>
    ) 
  }

  renderTicketsAndProgram(formikProps) {
    const { fields } = this.props;
    return (
      <>
        {/* <div className="row">
          <div className="col-xs-12">
            <label className="required"> Registration Program (if preregistering) </label>
            {this.renderSelectField('programname',formikProps, programName(fields))}
          </div>
        </div> */}
        {this.renderTicketSection(formikProps)}
      </>
    )
  }

  renderTicketSection(formikProps) {
    const { ticketTypes } = this.props;
    return ticketTypes.map(ticket=>(
      <div className="row" style={{marginBottom: "5px", display:"flex", alignItems:"center"}}>
        <div className="col-xs-12 col-sm-6">
          <label style={{margin:0}}>{ticket.name.replace("Day Program |","")}</label>
        </div>
        <div className="col-xs-12 col-sm-6">
          {this.renderSelectField(ticket.slug,formikProps, this.getTicketOptions())}
        </div>
      </div>
    ))
  }

  getTicketOptions() {
    return [0,1,2,3,4,5,6,7,8,9,10].map(x=>{return {
      value: `${x}`,
      label: `${x}`
    }})
  }

  isEmailValid(email) {
    return String(email||"").toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  renderTravelSection(formikProps) {
    const { values } = formikProps;
    const { fields } = this.props;
    return (
      <div className="row">
        {/* <div className="col-xs-12 col-sm-6">
          <label className="required"> Travel Mode </label>
          {this.renderSelectField('travelinformation',formikProps, travelInformation(fields))}
        </div>
        {
          values.user.travelinformation == "Other" ? (
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Please Specify: </label>
            {this.renderField('travelinformationother',formikProps)}
          </div>
          ) : ''
        } */}
        <div className="col-xs-12 sg-registration-large-copy">
          <br/>
          Does the ticket holder require accommodations? (Optional)
        </div>
        <div className="col-xs-12">
          {this.renderCheckbox('requirehotel',formikProps, {
            forceValue: "True"
          })}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px", marginRight: "20px"}}>Yes</label>
          {this.renderCheckbox('requirehotel',formikProps, {
            forceValue: "False"
          })}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>No</label>
        </div>
        {values.user.requirehotel == "True" ? (
          <>
            <div className="col-xs-12 col-sm-6">
              <label className="required"> Arrival Day </label>
              {this.renderField('arrivaldate',formikProps)}
            </div>
            <div className="col-xs-12 col-sm-6">
              <label className="required"> Departure Day </label>
              {this.renderField('departuredate',formikProps)}
            </div>
          </>
        ):""}

      </div>
    )
  }

  renderTicketRequestor(formikProps) {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <label className="required"> First Name </label>
          {this.renderField('requestornamefirst',formikProps)}
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className="required"> Last Name </label>
          {this.renderField('requestornamelast',formikProps)}
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className="required"> Email Address </label>
          {this.renderField('requestoremail',formikProps)}
        </div>
      </div>
    )
  }

  renderTicketHolderInformation(formikProps) {
    const { values } = formikProps;
    const { user } = values;
    return (
      <>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <label className="required"> Attendee Type </label>
          {this.renderSelectField('attendeetype',formikProps, this.attendeeTypeOptions())}
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className="required"> Attendee Source </label>
          {this.renderSelectField('attendeesource',formikProps, this.attendeeSourceOptions())}
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className="required"> Status </label>
          {this.renderSelectField('status',formikProps, this.statusOptions())}
        </div>
        {/* <div className="col-xs-12 col-sm-6">
          {user.attendee_type == "Other" ? (
            <>
            <label className="required"> Please Specify </label>
            {this.renderSelectField('attendeetypeother',formikProps, this.attendeeTypeOptions())}
            </>
          ):""}
        </div> */}
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <label className="required"> First Name </label>
          {this.renderField('name_first',formikProps)}
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className="required"> Last Name </label>
          {this.renderField('name_last',formikProps)}
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className="required"> Email Address (must be unique)</label>
          {this.renderField('email',formikProps)}
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className=""> Mobile Telephone </label>
          {this.renderField('work_phone',formikProps)}
        </div>
      </div>
      {this.renderEmailTakenError(formikProps)}
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <label className=""> Job Title </label>
          {this.renderField('job_title',formikProps)}
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className=""> Company </label>
          {this.renderField('company',formikProps)}
        </div>
        <div className="col-xs-12 sg-registration-large-copy">
          <br/>
          Would you like to include the ticket holder in future program communications (i.e. KBYG)?
        </div>
        <div className="col-xs-12">
          {this.renderCheckbox('includefurthercommunications',formikProps, {
            forceValue: "True"
          })}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px", marginRight: "20px"}}>Yes</label>
          {this.renderCheckbox('includefurthercommunications',formikProps, {
            forceValue: "False"
          })}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>No</label>
        </div>
        <div className="col-xs-12 sg-registration-large-copy">
          <br/>
          Would you like to include your assistant on future communications regarding your registration? (Optional)
        </div>
        <div className="col-xs-12">
          {this.renderCheckbox('includeassistant',formikProps, {
            forceValue: "True"
          })}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px", marginRight: "20px"}}>Yes</label>
          {this.renderCheckbox('includeassistant',formikProps, {
            forceValue: "False"
          })}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>No</label>
        </div>
        {user.includeassistant == "True" ? (
          <>
            <div className="col-xs-12 col-sm-6">
              <label className=""> Assistant Name </label>
              {this.renderField('assistantname',formikProps)}
            </div>
            <div className="col-xs-12 col-sm-6">
              <label className="required"> Assistant Email </label>
              {this.renderField('assistantemail',formikProps)}
            </div>
          </>
        ):""}
      </div>
      </>
    )
  }

  renderNotesSection(formikProps) {
    return (
      <div className="row">
        <div className="col-xs-12">
          <label className=""> Notes </label>
          {this.renderField('notes',formikProps, {component: 'textarea', not_required: true})}
        </div>
      </div>
    )
  }

  renderAdditionalTicketHolderInformationSection(formikProps) {
    console.log("#TODO");
    const { fields } = this.props;
    const { values } = formikProps;
    return (
      <div className="row">
        <div className="col-xs-12 sg-registration-large-copy">
          <br/>
          Does the ticket holder have any dietary preferences?
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className=""  style={{marginTop:"5px"}}> <br/> </label>
          {this.renderSelectField('dietarypreference',formikProps, dietaryRestrictions(fields),false,  {placeholder: "None", not_required: true})}
        </div>
        {values.user.dietarypreference == "Food Allergies" || values.user.dietarypreference == "Other" ? (
          <div className="col-xs-12 col-sm-6">
            <label className=""  style={{marginTop:"5px"}}> Please specify</label>
            {this.renderField('dietarypreferenceother',formikProps, false, {not_required: true})}
          </div>
        ): ""}
        <div className="col-xs-12 sg-registration-large-copy">
          <br/>
          Does the ticket holder have any physical needs or medical restrictions we can accommodate to make their attendance more comfortable for them?
        </div>
        <div className="col-xs-6">
          <label className="" style={{marginTop:"5px"}}> <br/> </label>
          {this.renderSelectField('specialaccomodations',formikProps, specialAccomodations(fields), false, {placeholder: "None", not_required: true})}
        </div>
        {values.user.specialaccomodations == "Other" ? (
          <div className="col-xs-12 col-sm-6">
            <label className=""  style={{marginTop:"5px"}}> Please specify</label>
            {this.renderField('specialaccomodationsother',formikProps, false, {not_required: true})}
          </div>
        ): ""}
      </div>
    )
  }

  renderCheckbox(name, formikProps, renderOptions={}) {
    let value = false;
    let customValidation = renderOptions.customValidation||false
    try {
      value = formikProps.values['user'][name] == "True";
    }catch(e){

    }
    if(renderOptions['forceValue'] != undefined ) {
      value = formikProps.values['user'][name] == renderOptions['forceValue']
    }
    return(
      <img
        src={ value  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          if(renderOptions['forceValue'] != undefined) {
            user[name] = renderOptions['forceValue']
          }else {
            user[name] = value ? "False" : "True"
          }

          formikProps.setFieldValue('user', user)
        }}
      />
    )
  }


  statusOptions() {
    return ['preregistered','registered'].map(x=>{return{value: x, label: x}});
  }

  optOutOptions() {
    return [
      {value: true, label: "True"},
      {value: false, label: "False"}
    ]
  }

  attendeeTypeOptions() {
    const { fields } = this.props;
    return ((fields['attendeetype']||{}).options||[]).map(x=>
      x.label
    ).map(x=>{return{value: x, label: x}});
  }


  attendeeSourceOptions() {
    const { fields } = this.props;
    return ((fields['attendeesource']||{}).options||[]).map(x=>
      x.label
    ).map(x=>{return{value: x, label: x}});
  }

  renderSelectField(name, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    const customValidation = renderOptions['customValidation']||false;
    const erroring = !renderOptions['not_required'] && checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['user'][name])[0]
    }catch (e) {

    }

    return (
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
        value={options ? value : ""}
        onChange={(option) => {
          const { user } = formikProps.values;
          user[name] = option.value
          formikProps.setFieldValue('user', user)
        }}
        filterOption={this.customFilter}
      />
    )
  }

  isFormValid(formikProps) {
    //TODO
    const { fields } = this.props;
    const { values } = formikProps;
    let fieldList = [
      'email',
      'name_first',
      'name_last',
      'attendeetype',
      'requestornamefirst',
      'requestornamelast',
      'requestoremail',
      'work_phone',
      'job_title',
      'company',

      // 'travelinformation',
      // 'dietarypreference',
      // 'specialaccomodations',
    ];

    if (values["user"]["bringingguest"]) {
      fieldList = fieldList.concat(["guestfname", "guestlname"])
    }

    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    return true;
  }

  attemptSubmit(formikProps = {}){
    if(!this.isFormValid(formikProps)){
      this.setState({
        error: "Please make sure all required fields have been properly filled in",
        checkError: true
      })
      return;
    }else {
      //TODO send request
      this.setState({
        checkError: false,
      })
      formikProps.submitForm();
      return;
    }
  }

  getInitialValues() {
    const { participant, ticketTypes } = this.props;
    let values = {
      programname: "Customer",
      name_first: participant ? participant.name_first : "",
      name_last: participant ? participant.name_last : "",
      email: participant ? participant.email : "",
      work_phone: participant ? participant.work_phone : "",
      job_title: participant ? participant.job_title : "",
      company: participant ? participant.company : "",
      address_country: participant ? participant.address_country : "",
      includeassistant: participant ? participant.registration_answers['includeassistant']:"",
      assistantname: participant ? participant.registration_answers['assistantname']:"",
      assistantemail: participant ? participant.registration_answers['assistantemail']:"",
      ecfname: participant ? participant.registration_answers['ecfname']:"",
      eclname: participant ? participant.registration_answers['eclname']:"",
      ecnumber: participant ? participant.registration_answers['ecnumber']:"",
      ecrelation: participant ? participant.registration_answers['ecrelation']:"",
      bringingguest: participant ? participant.registration_answers['bringingguest']:"",
      guestageconfirm: participant ? participant.registration_answers['guestageconfirm']:"",
      guestfname: participant ? participant.registration_answers['guestfname']:"",
      guestlname: participant ? participant.registration_answers['guestlname']:"",
      attendeesource: participant ? participant.registration_answers['attendeesource']:"",
    }

    ticketTypes.map(ticket=>{
      values[ticket.slug] = participant ? participant.registration_answers[ticket.slug] : "0"
    })

    return values;
  }

  renderField(name,formikProps, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && ((formikProps.values.user[name]||"").length <= 0 || properties['customValidation']);
    if(properties['not_required']) {
      erroring = false || properties['customValidation'];
    }
    return (
      <Field {...(properties['inputProps']||{})} disabled={properties['disabled']} onKeyUp={() => properties['onKeyUp'](formikProps)} component={properties['component']} className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`user[${name}]`}/>
    )
  }

  isEmailTaken(formikProps) {
    const { participants, participant } = this.props;
    const { values } = formikProps;
    const { user } = values;
    const { email } = user
    return participants.filter(x=> x.gid != (participant||{}).gid).filter(x=>x.email == email).length > 0
  }

  renderEmailTakenError(formikProps) {
    if(this.isEmailTaken(formikProps)) {
      return (
        <div className="col-xs-12 text-center">
          <div className="registration-field-error text-center">
            This email is used by another used user. Saving this may overwrite them. Are you sure you want to do this?
            <br/>
            <br/>
          </div>
        </div>
      )
    }
  }

  render() {
    const { user, participant } = this.props;
    const { checkError, submitError, error, loading } = this.state;
    return (
      <div className="registration-form" onClick={(e)=>{e.stopPropagation()}}>
        <Formik
          initialValues={{
            gid: participant ? participant.gid : "",
            user: this.getInitialValues()
          }}
          onSubmit={(values, actions) => {
            this.submitParticipant(values)
          }}
          render={(formikProps) => (
            <Form
              onSubmit={e => { e.preventDefault(); e.stopPropagation(); }}
              id="sg-admin-participant-form sg-registration-copy"
              style={{border: "0", textAlign: 'left'}}
            >
              {this.renderSections(formikProps)}
              <div className="col-xs-12 text-center">
                <div className="registration-field-error text-center">
                  {checkError && !this.isFormValid(formikProps) ? error : ""}
                  {submitError}
                </div>
              </div>
              <div  className="text-left" style={{marginBottom: "10px"}}>
                {loading ? (
                  <button disabled={true} className="att-proam-button att-proam-submit-button">
                     Saving...
                  </button>
                ):(
                  <button onClick={()=>this.attemptSubmit(formikProps)} className="att-proam-button att-proam-submit-button">
                     Submit
                  </button>
                )}
              </div>
            </Form>
        )}/>
      </div>
    );
  }
}

export default AdminParticipantForm;
