import React from "react";
import PropTypes from "prop-types";
import AdminParticipantForm from "./AdminParticipantForm"
import Modal from "react-modal";

class AdminParticipantModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      activated: false,
      attemptActivationCode: "",
      error: null,
    }
      this.toggleModal = this.toggleModal.bind(this);
      this.savedFormCallback = this.savedFormCallback.bind(this);
      this.setActive = this.setActive.bind(this);
      this.attemptActivation = this.attemptActivation.bind(this);
  }

  toggleModal(e) {
    const { modalOpen } = this.state;
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      modalOpen: !modalOpen,
      activated: false,
      attemptActivationCode: "",
    });
  }

  renderActivationForm() {
    const { attemptActivationCode, error } = this.state;
    return (
      <div className="sg-admin-ticketing-activation-form" onClick={(e)=>{e.stopPropagation()}}>
        <label> Please Input Access Code to Continue </label>
        <input
          value={attemptActivationCode}
          onChange={(e)=>this.setState({
            attemptActivationCode: e.target.value
          })}
          className="form-control"
        />
        <br/>
        <button
          onClick={this.attemptActivation}
          className="att-proam-button att-proam-submit-button"
        > Submit </button>
        {error ? <div className="error">{error}</div>:""}
      </div>
    )
  }

  attemptActivation() {
    const { attemptActivationCode } = this.state;
    this.setState({
      error: null
    })
    var ids = [
      '4191',
      '9553',
    ]
    if(ids.indexOf(attemptActivationCode) != -1) {
      this.setState({
        activated: true,
        error:null
      })
    }else {
      this.setState({
        error: "Code not found. Please try another."
      })
    }
  }

  renderModal() {
    const { modalOpen, activated} = this.state;
    if(modalOpen) {
      if(!activated) {
        return (
          this.renderActivationForm()
        )
      }
      return (
        <AdminParticipantForm
          {...this.props}
          savedFormCallback={this.savedFormCallback}
        />
      )
    }
  }

  savedFormCallback(attendees) {
    const { updateParticipantCallback } = this.props;
    if(updateParticipantCallback) {
      updateParticipantCallback(attendees);
    }
    this.setState({
      modalOpen:false,
      activated: false,
      attemptActivationCode: "",
      error:null
    })
  }

  setActive() {
    this.setState({
      activated: true,
      error:null,
    })
  }

  render() {
    const { modalOpen } = this.state;
    const { user, participant, outerClassNames, outerStyle, children} = this.props;
    return (
      <>
        <div className={outerClassNames} style={{...(outerStyle||{})}} onClick={(e) => this.toggleModal(e)} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default AdminParticipantModal;
